.banner {
  top: 0;
  background-color: var(--accent-dark);
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 900;
  z-index: 999;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .banner {
    font-size: 3rem;
  }
}
