@import url(https://fonts.googleapis.com/earlyaccess/cwtexming.css);
@import url(https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&family=Noto+Sans+HK:wght@100..900&display=swap);

.App,
.App input,
.App button {
  font-size: 1.4rem !important;
  font-family: "serif", "cwTeXMing", "PMingLiU", "新細明體";
}
.App .alert {
  font-size: 1.8rem !important;
}
.App .alert-success {
  color: #0b2211;
}

.App h1 {
  font-family: "Noto Sans HK", sans-serif;
}

.App label:not(.form-check-label) {
  font-weight: 500;
  font-family: "Noto Sans HK", sans-serif;
}

:root {
  --accent-dark: rgb(54, 83, 229);
  --accent-light: rgb(93, 117, 239);
}

a {
  color: var(--accent-dark);
  text-decoration: none;
}

.FormError {
  color: red;
  margin-top: 5px;
}

.panel {
  padding: 1rem;
  background: rgb(246, 248, 250);
}

.disclaimer {
  color: var(--accent-light) !important;
  font-size: 1.1rem;
  font-weight: 500;
}
